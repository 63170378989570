import React from "react";

const viewportContext = React.createContext({});

const mobileWidth = 700;
const tableWidth = 1000;

const ViewportProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= mobileWidth
  );
  const [isTablet, setIsTablet] = React.useState(
    window.innerWidth > mobileWidth && window.innerWidth <= tableWidth
  );
  const [isDesktop, setIsDesktop] = React.useState(
    window.innerWidth > tableWidth
  );
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
    setIsMobile(window.innerWidth <= mobileWidth);
    setIsTablet(
      window.innerWidth > mobileWidth && window.innerWidth <= tableWidth
    );
    setIsDesktop(window.innerWidth > tableWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider
      value={{ screenWidth, screenHeight, isMobile, isTablet, isDesktop }}
    >
      {children}
    </viewportContext.Provider>
  );
};
export default ViewportProvider;

export const useViewport = () => {
  const {
    screenWidth,
    screenHeight,
    isMobile,
    isTablet,
    isDesktop,
  } = React.useContext(viewportContext);
  return { screenWidth, screenHeight, isMobile, isTablet, isDesktop };
};
