import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

window.widgetConfig = window.widgetConfig || [];
const widgetConfigArray = window.widgetConfig;
widgetConfigArray.map((options) => {
  let root = document.getElementById(options.id);
  if (typeof root != "undefined" && root != null) {
    ReactDOM.unstable_createRoot(root).render(
      <React.StrictMode>
        <BrowserRouter basename={options.currentPath}>
          <App options={options} />
        </BrowserRouter>
      </React.StrictMode>
    );
  }
  return true;
});
