import React, { Suspense, lazy } from "react";
import "intersection-observer";
import { BrowserRouter } from "react-router-dom";
import ViewPortProvider from "./hooks/ViewPortProvider";
import { SaveScrollPosition } from "./hooks/ScrollPosition";

const GolvForm = lazy(() => import("./components/Offert/Form"));
const EmailForm = lazy(() => import("./components/Offert/EmailForm"));
const ProjectDetails = lazy(() => import("./components/ProjectMetaBox/Admin"));
const UserMeta = lazy(() => import("./components/Offert/UserMeta"));
const ProjectList = lazy(() => import("./components/ProjectList/Admin"));

function getView(options) {
  switch (options.widget) {
    case "GolvForm":
      return <GolvForm options={options} />;
    case "EmailForm":
      return <EmailForm options={options} />;
    case "ProjectDetails":
      return <ProjectDetails options={options} />;
    case "ProjectList":
      return <ProjectList options={options} />;
    case "UserMeta":
      return <UserMeta options={options} />;
    default:
      break;
  }
}

function App(props) {
  return (
    <BrowserRouter basename={props.options.currentPath}>
      <ViewPortProvider>
        <>
          <Suspense fallback={<div></div>}>{getView(props.options)}</Suspense>
        </>
      </ViewPortProvider>
    </BrowserRouter>
  );
}

export default App;
